<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row mt-8">
      <h2 class="text-lg font-medium">{{ i18n('admin.title') }}</h2>
    </div>
    <!-- BEGIN: HTML Table Filter -->
    <TableFilter
      :disabled="loading"
      :filter-fields="filterFields"
      @filter="doFilter"
      @reset="doResetFilter"
    >
    </TableFilter>
    <!-- END: HTML Table Filter -->

    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div
        class="
          flex flex-col
          sm:flex-row sm:items-end
          xl:items-start
          justify-end
        "
      >
        <div class="flex mt-5 sm:mt-0">
          <button
            v-if="hasPermissionToCreate"
            class="btn bg-theme-31 text-white sm:w-auto pb-1 pt-1"
            @click="$router.push('/admins/new')"
          >
            <PlusIcon class="w-5 h-5 mr-2 ml-2" /> {{ i18n('common.create') }}
          </button>
          <button
            v-if="hasPermissionToPrint"
            id="tabulator-print"
            class="
              btn btn-outline-secondary
              dark:text-white
              w-1/2
              sm:w-auto
              mr-2
              ml-2
            "
            @click="onPrint"
          >
            <PrinterIcon class="w-4 h-4 mr-2 ml-2" />
            {{ i18n('common.table.print') }}
          </button>
        </div>

        <div class="flex mt-5 sm:mt-0">
          <div class="dropdown w-1/2 sm:w-auto">
            <button
              v-if="hasPermissionToExport"
              class="
                dropdown-toggle
                btn btn-outline-secondary
                dark:text-white
                w-full
                sm:w-auto
              "
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
              {{ i18n('common.table.export') }}
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2 sm:mr-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  id="tabulator-export-xlsx"
                  href="javascript:;"
                  class="
                    flex
                    items-center
                    block
                    p-2
                    transition
                    duration-300
                    ease-in-out
                    bg-white
                    dark:bg-dark-1
                    hover:bg-gray-200
                    dark:hover:bg-dark-2
                    rounded-md
                  "
                  @click="onDownloadPdf"
                >
                  <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
                  {{ i18n('common.table.exportOptions.exportToPDF') }}
                </a>
                <a
                  id="tabulator-export-html"
                  href="javascript:;"
                  class="
                    flex
                    items-center
                    block
                    p-2
                    transition
                    duration-300
                    ease-in-out
                    bg-white
                    dark:bg-dark-1
                    hover:bg-gray-200
                    dark:hover:bg-dark-2
                    rounded-md
                  "
                  @click="onExportXlsx"
                >
                  <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
                  {{ i18n('common.table.exportOptions.exportToXLSX') }}
                </a>
              </div>
            </div>
          </div>
          <div
            v-if="hasPermissionToDestroy || hasPermissionToChangeStatus"
            class="dropdown w-1/2 sm:w-auto"
            :class="isRTL ? 'lg:mr-2 mr-2' : 'lg:ml-2 ml-2'"
          >
            <button
              class="btn btn-outline-secondary w-full sm:w-auto dark:text-white"
              aria-expanded="false"
              :disabled="selected.length == 0"
              :class="selected.length > 0 ? 'dropdown-toggle' : ''"
            >
              {{ i18n('common.actions') }}
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2 sm:mr-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  v-if="hasPermissionToDestroy"
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#delete-modal-preview"
                  class="
                    flex
                    items-center
                    block
                    p-2
                    transition
                    duration-300
                    ease-in-out
                    bg-white
                    dark:bg-dark-1
                    hover:bg-gray-200
                    dark:hover:bg-dark-2
                    rounded-md
                  "
                >
                  <Trash2Icon class="w-4 h-4 mr-1 ml-1" />
                  {{ i18n('common.delete') }}
                </a>
                <!-- data-toggle="modal"
                  data-target="#activate-modal-preview" -->
                <a
                  v-if="hasPermissionToChangeStatus"
                  href="javascript:;"
                  @click="doEnableAllSelected()"
                  class="
                    flex
                    items-center
                    block
                    p-2
                    transition
                    duration-300
                    ease-in-out
                    bg-white
                    dark:bg-dark-1
                    hover:bg-gray-200
                    dark:hover:bg-dark-2
                    rounded-md
                  "
                >
                  <UnlockIcon class="w-4 h-4 mr-1 ml-1" />
                  {{ i18n('common.activate') }}
                </a>
                <!-- data-toggle="modal"
                  data-target="#disable-modal-preview" -->
                <a
                  v-if="hasPermissionToChangeStatus"
                  href="javascript:;"
                  @click="doDisableAllSelected()"
                  class="
                    flex
                    items-center
                    block
                    p-2
                    transition
                    duration-300
                    ease-in-out
                    bg-white
                    dark:bg-dark-1
                    hover:bg-gray-200
                    dark:hover:bg-dark-2
                    rounded-md
                  "
                >
                  <LockIcon class="w-4 h-4 mr-1 ml-1" />
                  {{ i18n('common.disable') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref="table" class="relative">
        <loading-spinner
          type="half-circle"
          :duration="1000"
          :size="40"
          color="#000"
          :loading="loading"
        />
        <div class="overflow-x-auto">
          <!-- scrollbar-hidden -->
          <table
            class="table table-report sm:mt-2"
            id="adminTable"
            ref="tableRef"
          >
            <thead>
              <tr>
                <th class="whitespace-nowrap" id="">#</th>
                <!-- border-b-2 dark:border-dark-5 -->
                <th class="whitespace-nowrap text-center" id="ignore-1">
                  <!-- border-b-2 dark:border-dark-5 -->
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="selectAll"
                    @click="select"
                  />
                </th>
                <th
                  v-for="col in columns"
                  :key="col.name"
                  :id="
                    col.name === 'action'
                      ? 'ignore-2'
                      : col.name === 'avatar'
                      ? 'ignore-3'
                      : ''
                  "
                  class="text-center whitespace-nowrap"
                >
                  {{ i18n(col.label) }}
                </th>
              </tr>
            </thead>
            <tbody v-if="rows.length">
              <tr v-for="(row, index) in rows" :key="index" class="intro-x">
                <td class="w-40">
                  <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                    {{ rowIndex(index) }}
                  </div>
                </td>
                <td id="ignore-4">
                  <div class="text-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :value="row.id"
                      v-model="selected"
                    />
                  </div>
                </td>
                <td
                  class="text-center flex items-center justify-center"
                  id="ignore-5"
                >
                  <app-list-item-image
                    :value="row.avatar"
                    type="user"
                  ></app-list-item-image>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row, 'userName') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row, 'email') }}
                  </div>
                </td>
                <td class="text-center">
                  <div
                    class="font-medium whitespace-nowrap"
                    style="direction: ltr !important"
                  >
                    {{ presenter(row, 'phoneNumber') }}
                  </div>
                </td>
                <td class="text-center">
                  <div
                    class="
                      font-medium
                      whitespace-nowrap
                      text-theme-10
                      dark:text-theme-30
                    "
                    style="direction: ltr !important"
                  >
                    {{ presenter(row, 'role') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterDate(row, 'createdAt') }}
                  </div>
                </td>
                <td class="w-40">
                  <div
                    class="flex items-center justify-center whitespace-nowrap"
                    :class="{
                      'text-theme-24': row.status == 'disabled',
                      'text-green-600 dark:text-theme-30':
                        row.status == 'enabled'
                    }"
                  >
                    <CheckSquareIcon class="w-4 h-4 mr-2 ml-2" />
                    {{
                      row.status == 'enabled'
                        ? i18n('common.activated')
                        : i18n('common.disabled')
                    }}
                  </div>
                </td>
                <td class="table-report__action w-56" id="ignore-6">
                  <div class="flex justify-center items-center gap-3">
                    <router-link
                      class="
                        text-edit
                        dark:text-theme-30
                        flex
                        items-center
                        gap-2
                        whitespace-nowrap
                      "
                      :to="`/admins/${row.id}/edit`"
                      v-if="hasPermissionToEdit"
                    >
                      <EditIcon class="w-4 h-4" /> {{ i18n('common.edit') }}
                    </router-link>

                    <router-link
                      class="
                        text-view
                        dark:text-theme-30
                        flex
                        items-center
                        gap-2
                        whitespace-nowrap
                      "
                      :to="`/admins/${row.id}`"
                    >
                      <EyeIcon class="w-4 h-4" /> {{ i18n('common.view') }}
                    </router-link>
                    <!-- <router-link
                      class="
                        text-delete
                        dark:text-theme-30
                        flex
                        items-center
                        gap-2
                        whitespace-nowrap
                      "
                      :to="`/admins/${row.id}/access-pages`"
                      v-if="
                        hasPermissionToAccessPages && currentUser.id != row.id
                      "
                    >
                      <LockIcon class="w-4 h-4" />
                      {{ i18n('common.permission') }}
                    </router-link> -->
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-empty-page
            v-if="!rows.length && !loading"
            :label="i18n('empty.admin')"
            label-class="text-lg font-medium"
            icon-size="4x"
            class="box w-full py-64"
          ></app-empty-page>
        </div>
        <Pagination
          v-if="rows.length"
          :page="currentPage"
          :pageSize="pagination.limit"
          :rowsNumber="pagination.total"
          :showSizeChanger="true"
          :disabled="!rows.length"
          position="center"
          @next-page="doNextPage"
          @prev-change="doPrevPage"
          @size-change="doChangePaginationPageSize"
        ></Pagination>
      </div>
    </div>
    <!-- END: HTML Table Data -->

    <!-- BEGIN: Delete Modal -->
    <DeleteModal item="user" @delete="doDestroyAllSelected()" />
    <ActivateModal item="user" />
    <DisableModal item="user" />
    <!-- END: Delete Modal -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { defineComponent, ref, onMounted } from 'vue'
import feather from 'feather-icons'
import DeleteModal from '@/components/modals/delete-modal.vue'
import ActivateModal from '@/components/modals/activate-modal.vue'
import DisableModal from '@/components/modals/disable-modal.vue'
import { GenericModel } from '@/shared/model/generic-model'
import { helper as $h } from '@/utils/helper'
import print from 'print-js'
// import { AdminPermissions } from './admin-permissions'

export default defineComponent({
  components: {
    DeleteModal,
    ActivateModal,
    DisableModal
  },
  setup() {
    const tableRef = ref()
    // const tabulator = ref()
    const selected = ref([])
    const selectAll = ref(false)

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        // tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    onMounted(() => {
      reInitOnResizeWindow()
    })

    return {
      selected,
      selectAll,
      tableRef
    }
  },
  data() {
    return {
      filterInput: [],
      columns: [
        {
          name: 'avatar',
          field: 'avatar',
          label: 'iam.fields.avatar',
          align: 'center'
          // required: true,
        },
        {
          name: 'userName',
          field: 'userName',
          label: 'iam.fields.userName',
          align: 'center'
          // required: true,
        },
        {
          name: 'email',
          field: 'email',
          label: 'iam.fields.email',
          align: 'center'
          // required: true,
        },
        {
          name: 'phoneNumber',
          field: 'phoneNumber',
          label: 'iam.fields.phoneNumber',
          align: 'center'
          // required: true,
        },
        {
          name: 'role',
          field: 'role',
          label: 'iam.fields.role',
          align: 'center'
          // required: true,
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'iam.fields.joinDate',
          align: 'center'
          // required: true,
        },
        {
          name: 'status',
          field: 'status',
          label: 'iam.fields.status',
          align: 'center'
          // required: true,
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser',
      rows: 'admin/list/rows',
      pagination: 'admin/list/pagination',
      listLoading: 'admin/list/loading',
      destroyLoading: 'admin/destroy/loading'
    }),
    loading() {
      return this.listLoading || this.destroyLoading
    },
    hasPermissionToCreate() {
      // return new AdminPermissions(this.currentUser).create
      return true
    },
    hasPermissionToEdit() {
      // return new AdminPermissions(this.currentUser).edit
      return true
    },
    hasPermissionToDestroy() {
      // return new AdminPermissions(this.currentUser).destroy
      return true
    },
    hasPermissionToChangeStatus() {
      // return new AdminPermissions(this.currentUser).changeStatus
      return true
    },
    hasPermissionToPrint() {
      // return new AdminPermissions(this.currentUser).print
      return true
    },
    hasPermissionToExport() {
      // return new AdminPermissions(this.currentUser).export
      return true
    },
    hasPermissionToAccessPages() {
      // return new AdminPermissions(this.currentUser).accessPages
      return true
    },
    currentPage() {
      return this.pagination.currentPage || 1
    },
    rowIndex() {
      return (index) => {
        return index + this.pagination.limit * (this.currentPage - 1) + 1
      }
    },
    statusOptions() {
      return [
        { label: this.i18n('common.activated'), value: 'enabled' },
        { label: this.i18n('common.disabled'), value: 'disabled' }
      ]
    },
    filterFields() {
      return [
        {
          label: this.i18n('iam.filter.username'),
          value: 'userName',
          type: 'text'
        },
        {
          label: this.i18n('iam.filter.phoneNumber'),
          value: 'phoneNumber',
          type: 'text'
        },
        { label: this.i18n('iam.filter.email'), value: 'email', type: 'text' },
        {
          label: this.i18n('iam.filter.status'),
          value: 'status',
          type: 'list',
          options: this.statusOptions
        }
      ]
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.admins')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.admins')
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
  },
  methods: {
    ...mapActions({
      doFetch: 'admin/list/doFetch',
      doFetchNextPage: 'admin/list/doFetchNextPage',
      doFetchPreviousPage: 'admin/list/doFetchPreviousPage',
      doChangePaginationCurrentPage: 'admin/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'admin/list/doChangePaginationPageSize',
      doEnableAll: 'admin/list/doEnableAllSelected',
      doDisableAll: 'admin/list/doDisableAllSelected',
      doDestroyAll: 'admin/destroy/doDestroyAll'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    select() {
      this.selected = []
      if (!this.selectAll) {
        this.rows.forEach((element) => {
          this.selected.push(element.id)
        })
      }
    },
    clearSelection() {
      this.selected = []
      this.selectAll = false
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    async doFilter(filter, orderBy) {
      this.filterInput = []
      if (!filter || !filter.value) return
      if (Array.isArray(filter)) {
        this.filterInput.push(...filter)
      } else {
        this.filterInput.push(filter)
      }
      await this.doFetchFirstPage(orderBy)
    },
    async doResetFilter() {
      this.filterInput = []
      await this.doFetchFirstPage()
    },
    async doEnableAllSelected() {
      await this.doEnableAll(this.selected)
      this.clearSelection()
    },
    async doDisableAllSelected() {
      await this.doDisableAll(this.selected)
      this.clearSelection()
    },
    async doDestroyAllSelected() {
      await this.doDestroyAll(this.selected)
      this.clearSelection()
    },
    async doFetchFirstPage(orderBy) {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        filter: this.filterInput,
        pagination: {
          firstPage: true,
          limit: 10,
          action: 'next',
          orderBy: orderBy || 'createdAt',
          sortType: 'desc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    },
    onExportXlsx() {
      $h.exportToXlsx(this.tableRef, 'adminTable', 'admins')
    },
    onDownloadPdf() {
      $h.downloadPdf('#adminTable', 'admin', [1, 2, 9])
    },
    onPrint() {
      print({
        printable: 'adminTable',
        type: 'html',
        scanStyles: true,
        targetStyles: ['*'],
        maxWidth: 300,
        ignoreElements: [
          'ignore-1',
          'ignore-2',
          'ignore-3',
          'ignore-4',
          'ignore-5',
          'ignore-6'
        ]
      })
    }
  }
})
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
